import globals from "../globals.js"
export default {
    data() {
        return {

        }
    },
    methods:{

        getCompanyById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + "/company/" + id)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error)  {
                    reject(error.response.data.reason)
                }
            })
        },
        // Companies apis
        createConsultant(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/consultancy/", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getConsultantById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + "/consultancy/" + id)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        editConstultant(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + "/consultancy/" + id, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        deleteConsultant(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + `/consultancy/delete`, {
                        data: params
                    })
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getConsultantList(skip, limit, search_key,company_id,role) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/consultancy/?skip=${skip}&limit=${limit}&search_key=${search_key}&company_id=${company_id}&role=${role}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        regenerateConsultant(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/consultancy/details/regenerate", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        checkEmailExit(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/consultancy/check/if_email_exist", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        // reject(response.data.message)
                        resolve(response.data)
                        this.warning_msg = response.data.message
                        // this.$refs.warning_modal.open();
                        // setTimeout(() => {
                        //     this.$refs.warning_modal.close();
                        // }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.message
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
    }
}
